import { PropsWithChildren } from "react";

import * as Styled from "./styles";
import { SectionProps } from "./types";

const Section: React.FC<PropsWithChildren<SectionProps>> = ({
  isDiv,
  innerVariant,
  scrollTo,
  id,
  children,
  ...rest
}) => {
  const scrollToComponent = scrollTo ? (
    <Styled.ScrollToWrapper justifyContent="flex-end">
      <Styled.ScrollTo
        aria-label="Scroll to section button"
        size="big"
        href={`#${scrollTo}`}
      >
        <Styled.ScrollToIcon />
      </Styled.ScrollTo>
    </Styled.ScrollToWrapper>
  ) : null;

  return (
    <Styled.Wrapper as={isDiv ? "div" : undefined} {...rest}>
      {id && <Styled.Anchor {...{ id }} />}
      {innerVariant ? (
        <>
          <Styled.Inner variant={innerVariant}>
            {children}
          </Styled.Inner>
          <Styled.Inner isRelative variant={innerVariant}>
            {scrollToComponent}
          </Styled.Inner>
        </>
      ) : (
        <>
          {children}
          {scrollToComponent}
        </>
      )}
    </Styled.Wrapper>
  );
};

export default Section;
