import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

export const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
`;

export const Content = styled.iframe`
  width: 100%;
`;

export const CloseButton = styled.button`
  top: 1.25rem;
  right: 1.25rem;
  position: absolute;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary};
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    transition: ${({ theme }) => theme.transition};
    font-size: 1.25rem;
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
  @media ${media.desktop} {
    &:hover {
      svg {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
  }
`;
