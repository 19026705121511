import React from "react";

import IconClose from "@assets/svg/close.svg";

import ModalBase from "../ModalBase";

import * as Styled from "./styles";
import { ApplyModalProps } from "./types";

const ApplyModal: React.FC<ApplyModalProps> = ({
  applicationFormUrl,
  onClose
}) => (
  <ModalBase {...{ onClose }}>
    <Styled.Wrapper>
      <Styled.CloseButton onClick={onClose}>
        <IconClose />
      </Styled.CloseButton>
      <Styled.Content
        referrerPolicy="origin"
        src={applicationFormUrl}
      />
    </Styled.Wrapper>
  </ModalBase>
);

export default ApplyModal;
