import styled from "styled-components";
import NextLink from "next/link";
import { rgba } from "polished";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

export const Wrapper = styled.section`
  padding: 2.5rem 0;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};

  @media ${media.tablet} {
    padding: 7.5rem 0 5rem;
  }
`;

export const Content = styled.div`
  @media ${media.tablet} {
    display: grid;
    grid-template-columns: 18.75rem 1fr;
    grid-column-gap: 2rem;
  }
`;

export const Action = styled.div`
  @media ${media.maxTablet} {
    margin-bottom: 1.5rem;
  }
`;

export const PrevLink = styled(NextLink)`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.white};
  padding: 0.5rem 0;
  border-bottom: 1px solid currentColor;
  min-width: 9rem;
  transition: ${({ theme }) => theme.transition};

  svg {
    font-size: 1.25rem;
    width: 1em;
    height: 1em;
    fill: currentColor;
  }

  @media ${media.tablet} {
    min-width: 11.75rem;
  }

  @media ${media.desktop} {
    &:hover {
      color: ${({ theme }) => theme.colors.accent};
    }
  }
`;

export const PrevLinkLabel = styled.span`
  font-size: 1rem;
  line-height: 1.1;
  margin-left: 1rem;

  @media ${media.tablet} {
    font-size: 1.125rem;
  }
`;

export const InfoWrapper = styled.div``;

export const HeadingWrapper = styled.div`
  margin-bottom: 1.5rem;

  @media ${media.tablet} {
    margin-bottom: 2.5rem;
  }

  strong {
    display: block;
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const InfoList = styled.ul`
  @media ${media.tablet} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    max-width: 39.5rem;
  }
`;

export const InfoLabel = styled(Typography).attrs({
  variant: "subheading2",
  forwardedAs: "p"
})`
  margin-bottom: 0.25rem;
  color: ${({ theme }) => theme.colors.white};

  @media ${media.tablet} {
    margin-bottom: 1.125rem;
  }
`;

export const InfoDescription = styled(Typography)`
  color: ${({ theme }) => rgba(theme.colors.white, 0.8)};
`;

export const InfoListItem = styled.li`
  &:not(:last-of-type) {
    @media ${media.maxTablet} {
      margin-bottom: 0.75rem;
    }
  }
  &:first-of-type {
    ${InfoDescription} {
      text-transform: uppercase;
    }
  }
`;
