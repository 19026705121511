import { GetSpacingsReturnType } from "./types";

export const getSpacings = (
  ...spacings: (string | undefined)[]
): GetSpacingsReturnType => {
  const [
    spacingDesktopTop = "XXL",
    spacingDesktopBottom = "XXL",
    spacingMobileTop,
    spacingTabletTop,
    spacingMobileBottom,
    spacingTabletBottom
  ] = spacings;

  const topSpacing =
    (spacingMobileTop &&
      spacingTabletTop && [
        spacingMobileTop,
        spacingTabletTop,
        spacingDesktopTop
      ]) ||
    (!spacingMobileTop &&
      spacingTabletTop && [
        spacingTabletTop,
        spacingTabletTop,
        spacingDesktopTop
      ]) ||
    spacingDesktopTop;

  const bottomSpacing =
    (spacingMobileBottom &&
      spacingTabletBottom && [
        spacingMobileBottom,
        spacingTabletBottom,
        spacingDesktopBottom
      ]) ||
    (!spacingMobileBottom &&
      spacingTabletBottom && [
        spacingTabletBottom,
        spacingTabletBottom,
        spacingDesktopBottom
      ]) ||
    spacingDesktopBottom;

  return {
    topSpacing,
    bottomSpacing
  };
};
