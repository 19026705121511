import * as Styled from "./styles";
import { OfferBoxProps } from "./types";

const OfferBox: React.FC<OfferBoxProps> = ({
  href,
  title,
  salary,
  icon: Icon
}) => (
  <Styled.Wrapper {...{ href }}>
    <Styled.Title>{title}</Styled.Title>
    <Styled.Salary>{salary}</Styled.Salary>
    <Styled.Action>
      <Styled.ArrowRight />
      <Styled.IconWrapper>
        <Icon />
      </Styled.IconWrapper>
    </Styled.Action>
  </Styled.Wrapper>
);

export default OfferBox;
