import styled from "styled-components";

export const CmsContent = styled.div`
  font-size: 1rem;
  line-height: 1.75;
  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  strong {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  ul li {
    position: relative;
    padding-left: 1rem;
    // Override inline font style from traffit
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    &:not(:last-of-type) {
      margin-bottom: 0.75rem;
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.75rem;
      width: 0.3125rem;
      height: 0.3125rem;
      border: 1px solid currentColor;
      display: block;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }
`;
