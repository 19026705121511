import OfferBox from "./partials/OfferBox";
import * as Styled from "./styles";
import { OfferBoxListProps } from "./types";

const OfferBoxList: React.FC<OfferBoxListProps> = ({ items }) =>
  items?.length ? (
    <Styled.List aria-label="Offers list">
      {items.map(({ key, ...item }) => (
        <li {...{ key }}>
          <OfferBox {...item} />
        </li>
      ))}
    </Styled.List>
  ) : null;

export default OfferBoxList;
