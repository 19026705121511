import type { NextPage } from "next";
import {
  StoryblokComponent,
  useStoryblokState
} from "@storyblok/react";

import { PageProps } from "./types";
import { PAGE_TYPE } from "./consts";
import CareerContent from "./sections/CareerContent";
import JobContent from "./sections/JobContent";

const Page: NextPage<PageProps> = ({
  story: initialStory,
  jobs = [],
  jobOffer,
  pageType
}) => {
  const story = useStoryblokState(initialStory || null, {
    resolveRelations: [
      "caseStudiesCarousel.caseStudies",
      "caseStudyCard.caseStudy",
      "caseStudyCardCarousel.items"
    ]
  });

  switch (pageType) {
    case PAGE_TYPE.career:
      return story ? (
        <>
          <CareerContent
            {...{ jobs }}
            careerSlug={story?.full_slug}
          />
          {story?.content ? (
            <StoryblokComponent blok={story.content} />
          ) : null}
        </>
      ) : null;
    case PAGE_TYPE.job:
      return (
        <>
          {jobOffer ? <JobContent {...{ jobOffer }} /> : null}
          {story?.content ? (
            <StoryblokComponent blok={story.content} />
          ) : null}
        </>
      );
    default:
      return story?.content ? (
        <StoryblokComponent blok={story.content} />
      ) : null;
  }
};

export default Page;

//////////////////////
/////////////////////////////////////////////////////////
//////////
