import { Box } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import React from "react";

import { SpacerProps } from "./types";

const Spacer: React.FC<SpacerProps> = ({ pt }) => (
  <Box as="span" display="block" {...{ pt }} />
);

export default Spacer;
