import deburr from "lodash/deburr";

export const parseJobPageHeroTitle = (title: string): string[] => {
  const words = title.split(" ");
  const lastWord = words.pop() || "";
  const titleWithoutLastWord = words.join(" ");

  const result = [titleWithoutLastWord, lastWord];

  return result;
};

export const removeUndefinedFields = <T>(obj: T): T =>
  JSON.parse(JSON.stringify(obj));

export const convertRemToPixels = (rem: string) => {
  const remValue = parseFloat(rem.slice(0, rem.indexOf("rem")));

  if (typeof document === "undefined") {
    return remValue * 16;
  }

  return (
    remValue *
    parseFloat(getComputedStyle(document.documentElement).fontSize)
  );
};

export const slugify = (str: string): string =>
  deburr(str)
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
