import { slugify } from "@utils/misc";

import { TRAFFIT_PUBLIC_API_ENDPOINT } from "./consts";
import { TraffitJobOfferItem, ApiTraffitJobOfferItem } from "./types";

export const getJobOffers = async (): Promise<
  TraffitJobOfferItem[]
> => {
  const res = await fetch(TRAFFIT_PUBLIC_API_ENDPOINT);
  const jobsJson = (await res.json()) as ApiTraffitJobOfferItem[];

  return jobsJson.map((job) => ({
    ...job,
    slug: slugify(job.advert.name)
  }));
};

export const getSingleJobOffer = async (
  slug: string | string[]
): Promise<TraffitJobOfferItem | undefined> => {
  const jobOffers = await getJobOffers();
  const singleJobOffer = jobOffers.find(
    (offer: TraffitJobOfferItem) => offer.slug === slug
  );

  return singleJobOffer;
};

export const getSalary = (str: string): string => {
  const salary = str
    .replace(/<\/?span[^>]*>/g, "")
    .replace(/<\/?strong[^>]*>/g, "")
    .match(/Salary:.+PLN (NET|GROSS)+/i);

  return salary === null
    ? ""
    : salary[0].replace("Salary:", "").trim();
};
