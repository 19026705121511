import { theme } from "@pagepro-monorepo/ui";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { Inner } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import Spacer from "@components/layout/Spacer";
import OfferBoxList from "@components/molecules/OfferBoxList";
import Section from "@components/layout/Section";
import { getSpacings } from "@utils/getSpacings";

import { useOfferBoxListItems } from "../../hooks";

import { CareerContentProps } from "./types";
import { spacings } from "./consts";

const CareerContent: React.FC<CareerContentProps> = ({
  jobs,
  careerSlug
}) => {
  const items = useOfferBoxListItems(jobs, careerSlug);

  const { topSpacing, bottomSpacing } = getSpacings(...spacings);

  return (
    <Section
      backgroundColor={theme.colors.secondaryBackground}
      pt={topSpacing}
      pb={bottomSpacing}
    >
      <Inner>
        <Typography variant="heading3" as="h1" textAlign="center">
          <span style={{ color: "#f5333f" }}>Career</span>
          <br />
          Open Positions in Białystok or Remote
        </Typography>
        <Spacer pt={["2rem", "4rem", "8rem"]} />
        <OfferBoxList {...{ items }} />
      </Inner>
    </Section>
  );
};

export default CareerContent;
