import React, { Fragment } from "react";

import { CmsContent } from "@components/molecules/CmsContent/styles";

import * as Styled from "./styles";
import { JobOfferContentProps } from "./types";

const JobOfferContent: React.FC<JobOfferContentProps> = ({
  sections
}) =>
  sections.length ? (
    <Styled.Wrapper>
      <Styled.Content>
        {sections.map(({ key, heading, description }) => (
          <Fragment {...{ key }}>
            <Styled.HeadingWrapper>
              {heading && <Styled.Heading>{heading}</Styled.Heading>}
            </Styled.HeadingWrapper>
            <Styled.DescriptionWrapper>
              <CmsContent>{description}</CmsContent>
            </Styled.DescriptionWrapper>
          </Fragment>
        ))}
      </Styled.Content>
    </Styled.Wrapper>
  ) : null;

export default JobOfferContent;
