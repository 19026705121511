import React from "react";
import { useRouter } from "next/router";
import { Inner } from "@pagepro-monorepo/ui/lib/components/styles/Grid";

import IconArrowLeft from "@assets/svg/arrow-left.svg";

import * as Styled from "./styles";
import { HeroProps } from "./types";

const JobHero: React.FC<HeroProps> = ({ heading, salary }) => {
  const {
    query: { slug }
  } = useRouter();
  const href = Array.isArray(slug)
    ? slug.slice(0, -2).join("/")
    : slug || "#";

  return (
    <Styled.Wrapper>
      <Inner>
        <Styled.Content>
          <Styled.Action>
            <Styled.PrevLink {...{ href }}>
              <IconArrowLeft />
              <Styled.PrevLinkLabel>
                All positions
              </Styled.PrevLinkLabel>
            </Styled.PrevLink>
          </Styled.Action>
          <Styled.InfoWrapper>
            <Styled.HeadingWrapper>{heading}</Styled.HeadingWrapper>
            <Styled.InfoList>
              {salary && (
                <Styled.InfoListItem>
                  <Styled.InfoLabel>Salary</Styled.InfoLabel>
                  <Styled.InfoDescription>
                    {salary}
                  </Styled.InfoDescription>
                </Styled.InfoListItem>
              )}
            </Styled.InfoList>
          </Styled.InfoWrapper>
        </Styled.Content>
      </Inner>
    </Styled.Wrapper>
  );
};

export default JobHero;
