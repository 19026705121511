import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

export const Wrapper = styled.div``;

export const HeadingWrapper = styled.div``;

export const Heading = styled(Typography).attrs({
  variant: "subheading1",
  forwardedAs: "h2"
})`
  color: ${({ theme }) => theme.colors.accent};

  @media ${media.maxTablet} {
    margin: 2rem 0 1rem;
  }
`;

export const DescriptionWrapper = styled.div``;

export const Content = styled.div`
  @media ${media.tablet} {
    display: grid;
    grid-template-columns: 18.75rem 39.375rem;
    grid-gap: 6rem 2rem;
  }

  ${HeadingWrapper}:first-of-type + ${DescriptionWrapper} {
    p:first-of-type {
      font-size: 1.25rem;
    }
  }
`;
