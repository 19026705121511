import IconJamstack from "@assets/svg/icon-jamstack.svg";
import IconQuality from "@assets/svg/icon-quality.svg";
import IconReact from "@assets/svg/icon-react.svg";
import IconReactNative from "@assets/svg/icon-react-native.svg";

export const getTechnologyIcon = (
  title: string
): SvgComponentType => {
  const lowerCaseTitle = title.toLowerCase();

  if (lowerCaseTitle.includes("react")) {
    return lowerCaseTitle.includes("native")
      ? IconReactNative
      : IconReact;
  }

  if (lowerCaseTitle.includes("jamstack")) {
    return IconJamstack;
  }

  return IconQuality;
};
