import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";

import Link from "@components/atoms/Link";
import { IconArrowRight } from "@assets/svg";

export const Title = styled(Typography).attrs({
  variant: "body3",
  fontWeight: "medium",
  forwardedAs: "h2"
})`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 1.1rem;

  @media ${media.mobile} {
    margin-bottom: 1.375rem;
  }
`;

export const Salary = styled(Typography).attrs({
  variant: "subheading2",
  forwardedAs: "p"
})`
  color: ${({ theme }) => theme.colors.accent};
  margin-bottom: 1rem;
`;

export const ArrowRight = styled(IconArrowRight)`
  display: flex;
  fill: currentColor;

  transition: ${({ theme }) => theme.transition};
  transition-property: transform;
`;

export const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  color: ${({ theme }) => theme.colors.primary};
  transition: ${({ theme }) => theme.transition};
  transition-property: color, background-color;
  height: 100%;

  @media ${media.mobile} {
    padding: 2rem;
  }

  @media ${media.tablet} {
    min-height: 14.75rem;
  }

  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.accent};
      color: ${({ theme }) => theme.colors.white};

      ${Title} {
        color: ${({ theme }) => theme.colors.white};
      }

      ${Salary} {
        color: ${({ theme }) => theme.colors.white};
      }

      ${ArrowRight} {
        transform: translateX(0.625rem);
      }
    }
  }
`;

export const Action = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;

  svg {
    font-size: 2rem;
    width: 1em;
    height: 1em;
    fill: currentColor;
  }
`;
