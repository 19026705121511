import { useMemo } from "react";
import urlJoin from "url-join";

import {
  TraffitJobOfferItem,
  TraffitJobOfferContentSection
} from "@api/traffit/types";
import { getSalary } from "@api/traffit";
import { getTechnologyIcon } from "@utils/getTechnologyIcon";
import { OfferBoxListItem } from "@components/molecules/OfferBoxList/types";
import { JobOfferContentSection } from "@components/organisms/JobOfferContent/types";

import {
  ORDERED_CONTENT_SECTIONS,
  DESCRIPTION_CONTENT_SECTION,
  GEOLOCATION_CONTENT_SECTION,
  STORYBLOK_JOBS_SLUG
} from "./consts";
import { UseSingleOfferReturnType } from "./types";

export const useOfferBoxListItems = (
  jobs: TraffitJobOfferItem[],
  careerSlug: string
) =>
  useMemo<OfferBoxListItem[]>(
    () =>
      jobs?.map<OfferBoxListItem>(
        ({ id, advert: { values, name }, slug }) => {
          const description = values.find(
            (item) => item.field_id === DESCRIPTION_CONTENT_SECTION
          )?.value;

          return {
            key: id.toString(),
            href: urlJoin(careerSlug, STORYBLOK_JOBS_SLUG, slug),
            title: name,
            salary: getSalary(description || ""),
            icon: getTechnologyIcon(name)
          };
        }
      ),
    [jobs, careerSlug]
  );

export const useSingleOffer = ({
  advert: { values, name: title },
  application_form: applicationFormUrl
}: TraffitJobOfferItem): UseSingleOfferReturnType => {
  const filteredContentSections = values.filter(
    ({ field_id: fieldId }) => fieldId !== GEOLOCATION_CONTENT_SECTION
  );

  const geolocation = values.find(
    ({ field_id: fieldId }) => fieldId === GEOLOCATION_CONTENT_SECTION
  )?.value;

  const sortedContentSections = ORDERED_CONTENT_SECTIONS.reduce<
    TraffitJobOfferContentSection[]
  >((result, sectionId) => {
    const section = filteredContentSections.find(
      ({ field_id: fieldId }) => sectionId === fieldId
    );

    if (!section) {
      return result;
    }

    return [...result, section];
  }, []);

  const restContentSections = filteredContentSections
    .filter(
      ({ field_id: fieldId }) =>
        !sortedContentSections.find(
          (section) => section.field_id === fieldId
        )
    )
    .reverse();
  const allContentSections = [
    ...sortedContentSections,
    ...restContentSections
  ];
  const description = allContentSections.find(
    ({ field_id: fieldId }) => fieldId === DESCRIPTION_CONTENT_SECTION
  )?.value;
  const location: string = geolocation
    ? JSON.parse(geolocation).locality
    : "";

  const jobOfferContent = allContentSections.reduce<
    JobOfferContentSection[]
  >(
    (
      result,
      { value: sectionContent, name: heading, field_id: key }
    ) => {
      if (!sectionContent) {
        return result;
      }

      return [
        ...result,
        {
          key,
          heading,
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: `${sectionContent.replace(
                  /<\/?span[^>]*>/g,
                  ""
                )}`
              }}
            />
          )
        }
      ];
    },
    []
  );

  return {
    title,
    salary: getSalary(description || ""),
    location,
    jobOfferContent,
    applicationFormUrl
  };
};
