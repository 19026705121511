import Head from "next/head";
import { useCallback, useState } from "react";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import {
  Box,
  Inner
} from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import Button from "@pagepro-monorepo/ui/lib/components/atoms/Button";

import Section from "@components/layout/Section";
import Spacer from "@components/layout/Spacer";
import ApplyModal from "@components/molecules/modals/ApplyModal";
import JobHero from "@components/organisms/JobHero";
import JobOfferContent from "@components/organisms/JobOfferContent";
import { getSpacings } from "@utils/getSpacings";
import { parseJobPageHeroTitle } from "@utils/misc";

import { useSingleOffer } from "../../hooks";

import { JobContentProps } from "./types";
import { spacings, contentSpacings } from "./consts";

const JobContent: React.FC<JobContentProps> = ({ jobOffer }) => {
  const { title, salary, jobOfferContent, applicationFormUrl } =
    useSingleOffer(jobOffer);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = useCallback(() => setIsModalOpen(true), []);
  const handleModalClose = useCallback(
    () => setIsModalOpen(false),
    []
  );

  const { topSpacing, bottomSpacing } = getSpacings(...spacings);

  const {
    topSpacing: contentTopSpacing,
    bottomSpacing: contentBottomSpacing
  } = getSpacings(...contentSpacings);

  return (
    <>
      <Head key="job">
        <title>{title} - Pagepro</title>
        <meta property="og:title" content={`${title} - Pagepro`} />
      </Head>
      <JobHero
        heading={
          <Typography variant="heading2" as="h1">
            {parseJobPageHeroTitle(title).map((text, index) =>
              index === 0 ? (
                <strong key={text}>{text}</strong>
              ) : (
                <span key={text}>{text}</span>
              )
            )}
          </Typography>
        }
        {...{ salary }}
      />
      <Section pt={contentTopSpacing} pb={contentBottomSpacing}>
        <Inner>
          <JobOfferContent sections={jobOfferContent} />
        </Inner>
      </Section>
      <Section pt={topSpacing} pb={bottomSpacing}>
        <Inner>
          <Box pl={["0", "0", "20.75rem"]}>
            <Typography variant="subheading2" as="p">
              Apply Now
            </Typography>
            <Spacer pt={["1rem", "2rem"]} />
            <Typography variant="heading2" as="h2">
              <span style={{ color: "#f5333f" }}>
                Sounds Interesting?
              </span>
              <br />
              Join Our Team Today!
            </Typography>
            <Spacer pt={["2.5rem", "4.5rem"]} />
            <Button onClick={handleModalOpen}>Apply Now</Button>
          </Box>
        </Inner>
      </Section>
      {isModalOpen && applicationFormUrl && (
        <ApplyModal
          onClose={handleModalClose}
          {...{ applicationFormUrl }}
        />
      )}
    </>
  );
};

export default JobContent;
